import React, { useState, useEffect } from "react";

const NotesListPage = () => {
  const [notes, setNotes] = useState("-");

  useEffect(() => {
    getNotes()
  }, []);

  let getNotes = async () => {
    
    let response = await fetch(process.env.REACT_APP_ELBIL_API);
    let data = await response.json()
    setNotes(data);
  }



    return (
      // <div>
      //   {notes.map((note, index) => (
      //     <h3 key={index}>{note.test_pris}</h3>
      //   ))}
      // </div>
      <div>
       <h1>
        Pris er {notes.test_pris_1 ? notes.test_pris_1 : "-"}
        </h1> 
       {/* <button onClick={getNotes}>trykk</button> */}

    </div>
    );


};
export default NotesListPage;
